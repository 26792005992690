.login-side {
    background: linear-gradient(90deg, #4ADEDE 0%, #1AA7EC 100%);
}

.login-welcome-txt {
    // margin-left: 0;
    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 32px;

    /* or 107% */

    text-transform: uppercase;

    /* Heart Navy */

    color: #4752AD;

}

.login-form-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-input-view {
    
    background: linear-gradient(90deg, #797EF6 0%, #4ADEDE 100%);
    // opacity: 0.2;
    border-radius: 5px;
    height: 56px;
    width: 500px;
}
.login-input {
    opacity: 1 !important; 
    background-color: rgba(255, 255, 255, 0.8) ;
    border-radius: 5px;
    height: 56px;
    width: 500px;
    color: #7881F5;
}

.login-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(120, 129, 245, 1);
}


.login-btn {
    font-family: 'Noto Sans Thai';
    font-size: 16px;
    font-weight: 600;
    width: 500px;
    height: 56px;
    color: white;
    margin-top: 50px;

    border: none;
    outline: none;
    border-radius: 5px;
    background: linear-gradient(90deg, #4ADEDE 0%, #1AA7EC 100%);
    cursor: pointer;
}

.login-label {
    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    text-transform: capitalize;
    margin-top: 10px;
    margin-bottom: 10px;

    /* Navy Gradiant 2 */

    background: linear-gradient(270deg, #1E2F97 -5.47%, #797EF6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

// @font-face{
//     font-family: 'Noto Sans Thai';
//     src: local('Noto Sans Thai'), url(./fonts/noto_sans_thai.ttf) format('truetype');
//     font-style: normal;
//     font-weight: 600;
//     font-size: 20px;
//     line-height: 30px;
//     text-transform: capitalize;
//   }
@primary-color: #4752AD;@heading-color: #4752AD;@layout-header-background: #4752AD;@skeleton-color: #EEECF5;@descriptions-bg: #EEECF5;