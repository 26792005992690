.info-item-container {
    margin: 5px;
}

.info-item-name {
    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #1E2F97;
    padding-right: 15px;
}

.info-item-date {
    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
}

.info-item-content-container {
    margin-left: 5px;
    border-radius: 5px;
    padding: 5px;
    background: #F0F2F5;
}

.info-item-content {
    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #202124;
}
@primary-color: #4752AD;@heading-color: #4752AD;@layout-header-background: #4752AD;@skeleton-color: #EEECF5;@descriptions-bg: #EEECF5;