.chat-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 50px;
    min-height: 100%;
}

.outer-comments-container {
    height: 80%;
    // overflow: auto;
    display: flex;
    // flex-direction:column-reverse;
}

.inner-comments-container {
    /* height: 80%; */

    /* position: relative;
    display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    /* margin: 20px;
    width: 50px;
    height: 50px;
    background-color: red; */
    /* top:0px; */
}

.editor-container {
    height: 20%;
}

.admin-comment {
    float: right;
}

.client-comment {}

.logo {
    height: 32px;
    margin: 16px;
    display: flex;
    align-items: center;
    /* background: rgba(255, 255, 255, 0.3); */
    cursor: pointer;
}

.chat-text .ant-comment-content-detail {
    overflow-wrap: anywhere;
    width: fit-content;
    white-space: pre-line;
    border-radius: 10px;
    background-color: white;
    padding: 15px;
}

.chat-image .ant-image {
    // margin-left: 44px;
    border-radius: 10px;
    // background-color: white; // P Paan said remove this!
    padding: 15px;
}

.chat-sticker .ant-image {
    margin-left: 44px;
    border-radius: 10px;
    // background-color: white; // P Paan said remove this!
    padding: 15px;
}

.chat-text-right .ant-comment-content-detail {
    overflow-wrap: anywhere;
    width: fit-content;
    white-space: pre-line;
    border-radius: 10px;
    background-color: #7881F5;
    color: white;
    padding: 15px;
}


.chat-text-right .ant-comment-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.chat-image-right .ant-image {
    margin-left: 44px;
    border-radius: 10px;
    background-color: #7881F5;
    padding: 15px;
}

.chat-sticker-right .ant-image {
    margin-left: 44px;
    border-radius: 10px;
    background-color: #7881F5;
    padding: 15px;
}

.chat-video-right .ant-image {
    margin-left: 44px;
    border-radius: 10px;
    background-color: #7881F5;
    padding: 15px;
}

.ant-collapse-header-text {
    font-family: 'Noto Sans Thai';
    color: #4752AD;
    font-size: 22px;
    font-weight: 600;
}

.ant-collapse-header {
    background: white;
}

.overview-container {
    border: 1px solid #DADCE0;
}

.panel-header {}

.holding-container{
    background: #ffffff;
    // background-color:#EDE6F2;
    padding-left: 15px;
    padding-right: 15px;
    
    overflow: auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.holding-header-text{
    height: 100%;
    font-family: 'Noto Sans Thai';
    padding-left: 30px;
    background-color: #fff;
    display: flex;
    font-weight: 600;
    font-size: 22px;
    // height: 10%;
    color: #4752AD;
    align-items: center;
}


.holding-search-container{
    // display: flex;
    // align-items: center;
    padding-left: 30px;
    padding-right: 30px;
}
.holding-search{
    border-radius: 60px;
    height: 44px;
    padding-left: 30px;
}
.holding-profile-name{
    height: 100%;
    font-family: 'Noto Sans Thai';
    padding-left: 10px;
    background-color: #fff;
    display: flex;
    font-weight: 600;
    font-size: 18px;
    color: #202124;
    align-items: center;
}
@primary-color: #4752AD;@heading-color: #4752AD;@layout-header-background: #4752AD;@skeleton-color: #EEECF5;@descriptions-bg: #EEECF5;