.profile-container {
    display: flex;
    align-items: center;
    // justify-content: center;
    height: 10%;
    width: 100%;
}
.chat-preview-container{
    color: #736C7C;
    font-family: 'Noto Sans Thai';
    font-size: 12px;
    font-weight: 400;
    // display: flex; 
    // align-items: center;
    // justify-content: center;
}

.profile-name{
    font-family: 'Noto Sans Thai';
    font-size: 18px;
    font-weight: 600;
}

.preview-action-container {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 10%;
    width: 100%;
}

.forward-button {
    position: relative;
    margin-right: 10px;
    width: 150px;
    height: 40px;
    background: linear-gradient(90deg, #4ADEDE 0%, #1AA7EC 100%);


    border-radius: 5px;

    font-family: 'Noto Sans Thai';
    font-size: 16px;
    font-weight: 600;
}

.btn {

    font-family: 'Noto Sans Thai';
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    margin-right: 10px;
    width: 150px;
    height: 40px;

    border: none;
    outline: none;
    position: relative;
    z-index: 1;
    border-radius: 5px;
    background: linear-gradient(90deg, #4ADEDE 0%, #1AA7EC 100%);
    cursor: pointer;
}

.btn::before {
    content: "";
    position: absolute;
    left: 1px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    border-radius: 4px;
    background-color: white;
    z-index: -1;
    transition: 200ms;
}

.btn::after {
    content: attr(data);
    // font-size: 16px;
    background: linear-gradient(90deg, #4ADEDE 0%, #1AA7EC 100%);
    -webkit-background-clip: text;
    color: transparent;
    transition: 200ms;
}

// .btn:hover::before {
//     opacity: 50%;
//     top: 0px;
//     right: 0px;
//     bottom: 0px;
//     left: 0px;
// }

// .btn:hover::after {
//     color: #fff;
// }


.accept-button {
    border: none;
    margin-right: 10px;
    width: 150px;
    height: 40px;
    background: linear-gradient(90deg, #4ADEDE 0%, #1AA7EC 100%);
    color: #ffffff;
    border-radius: 5px;
    font-family: 'Noto Sans Thai';
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

@primary-color: #4752AD;@heading-color: #4752AD;@layout-header-background: #4752AD;@skeleton-color: #EEECF5;@descriptions-bg: #EEECF5;