.right-content{
    overflow-wrap: anywhere;
    width: fit-content;
    white-space: pre-line;
    border-radius: 10px;
    background-color: #7881F5;
    color: white;
    padding: 15px;
}
.right-content-pale{
    overflow-wrap: anywhere;
    width: fit-content;
    white-space: pre-line;
    border-radius: 10px;
    background-color: #a3a8ec;
    color: white;
    padding: 15px;
}
.right-content-container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.right-status{
    display: flex;
    align-items: flex-end;
}
.right-comment-container{
    margin-bottom: 16px;
}

.left-content{
    overflow-wrap: anywhere;
    width: fit-content;
    white-space: pre-line;
    border-radius: 10px;
    background-color: white;
    padding: 15px;
}
.left-content-container{
    // flex-direction: column;
}
.left-status{
    font-size: 10px;
    // display: flex;
    // align-items: flex-end;
}
.left-comment-container{
    margin-bottom: 16px;
}

.header-container{
    display: flex;
    align-items: center;
}
.author{
    margin-right: 5px;
    color: #1E2F97;
    font-size: 15px;
}
.datetime{
    font-size: 10px;
}
.right-status{
    margin-right: 5px;
    color: grey;
    font-size: 10px;
    // display: flex;
    // align-items: flex-end;
}
@primary-color: #4752AD;@heading-color: #4752AD;@layout-header-background: #4752AD;@skeleton-color: #EEECF5;@descriptions-bg: #EEECF5;