.page-header {
    color: #4752AD;
    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 60px;
    text-transform: capitalize;
}

.btn-create-issue {
    width: 204px;
    height: 54px;
    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 32px;
    color: #4752AD;
    border-radius: 50px;
}

.table-row {
    cursor: pointer;
}
@primary-color: #4752AD;@heading-color: #4752AD;@layout-header-background: #4752AD;@skeleton-color: #EEECF5;@descriptions-bg: #EEECF5;