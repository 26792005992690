.session-search-container{
    height: 10%;
    background-color: #fff;
    padding-left: 30px;
    padding-right: 30px;
}
.session-list-container {
    background: #ffffff;
    padding-left: 30px;
    padding-right: 30px;
    
    height: 80%;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

@font-face{
    font-family: 'Noto Sans Thai';
    src: local('Noto Sans Thai'), url(../../fonts/noto_sans_thai.ttf) format('truetype');
    font-style: normal;
}

.channels-container {
    
    background: #ffffff;
    height: 100vh;
    display: flex;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 50px;
    padding-right: 50px;
}

.channel-container {
    background: #ffffff;
    height: 100%;
    width: 50%;
    border: 1px solid #DADCE0;
}

.channel-container.left {
    background: #ffffff;
    height: 100%;
    width: 50%;
    
    // margin-right: 15px;
}

.issues-container {
    background: #ffffff;
    // background-color:#EDE6F2;
    padding-left: 30px;
    padding-right: 30px;
    
    // overflow: auto;
    height: 80%;
    // overflow: auto;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.channel-header {
    height: 10%;
    font-family: 'Noto Sans Thai';
    padding-left: 30px;
    background-color: #fff;
    display: flex;
    font-weight: 600;
    font-size: 22px;
    // height: 10%;
    color: #4752AD;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    align-items: center;
}
.issue-search-container{
    height: 10%;
    background-color: #fff;
    padding-left: 30px;
    padding-right: 30px;
}
@primary-color: #4752AD;@heading-color: #4752AD;@layout-header-background: #4752AD;@skeleton-color: #EEECF5;@descriptions-bg: #EEECF5;