// .ant-menu-item-selected {
//     background-color: #33BAC5 !important;
//     color: #02646C !important;
// }
// .ant-menu  .ant-menu-item-selected {
//     color: #02646C !important;
// }
.ant-menu {
    color: #ffffff;
    background-color: transparent;
    font-family: 'Noto Sans Thai';
    
}

.ant-menu.ant-menu-sub {
    
    background-color: transparent;
}

.ant-menu>.ant-menu-item:hover,
.ant-menu>.ant-menu-submenu:hover,
.ant-menu>.ant-menu-item-active,
.ant-menu>.ant-menu-submenu-active,
.ant-menu>.ant-menu-item-open,
.ant-menu>.ant-menu-submenu-open,
.ant-menu>.ant-menu-item-selected,
.ant-menu>.ant-menu-submenu-selected {
    color: #02646C !important;
}

.ant-menu>.ant-menu-item:hover,
.ant-menu>.ant-menu-item-active,
.ant-menu>.ant-menu-item-open,
.ant-menu>.ant-menu-item-selected {
    background-color: #33BAC5 !important;
}



.ant-layout-sider-trigger {
    background-color: transparent;
}

.ant-menu-submenu-arrow {
    color: #ffffff
}
@primary-color: #4752AD;@heading-color: #4752AD;@layout-header-background: #4752AD;@skeleton-color: #EEECF5;@descriptions-bg: #EEECF5;