@import '~antd/dist/antd.less';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face{
  font-family: 'Noto Sans Thai';
  src: local('Noto Sans Thai'), url(./fonts/noto_sans_thai.ttf) format('truetype');
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
}


@primary-color: #4752AD;@heading-color: #4752AD;@layout-header-background: #4752AD;@skeleton-color: #EEECF5;@descriptions-bg: #EEECF5;