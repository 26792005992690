
@font-face {
    font-family: 'Noto Sans Thai';
    src: local('Noto Sans Thai'), url(../../fonts/noto_sans_thai.ttf) format('truetype');
}

.profile-container {}

.ant-comment-content-author-name {
    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 20px !important;
    /* identical to box height */

    text-transform: capitalize;

    color: #202124 ;
}

.date-container {
    text-align: right;
    // padding-right: 25px;
    // position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.date-object {
    // position: absolute;
    // top: 50%;
    // transform: translateY(-50%);


    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
}
.issue-item:hover {
    cursor: pointer;
    background-color: #F0F2F5;
    // box-shadow: 0 0 4px red;
}

.issue-item-selected {
    // cursor: pointer;
    background-color: #F0F2F5;
    // box-shadow: 0 0 4px red;
}
@primary-color: #4752AD;@heading-color: #4752AD;@layout-header-background: #4752AD;@skeleton-color: #EEECF5;@descriptions-bg: #EEECF5;