.activity-log-list-content{
    font-family: 'Noto Sans Thai';
    font-style: normal; 
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}
.activity-log-list-header{
    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
}
@primary-color: #4752AD;@heading-color: #4752AD;@layout-header-background: #4752AD;@skeleton-color: #EEECF5;@descriptions-bg: #EEECF5;